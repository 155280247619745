import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Grid from '../grid/Grid'
import { largeGap, smallGap, maxMedia } from '../styles/utils'

const Page = ({ data = {} }) => {
  const { title, grid } = data.page
  return (
    <StyledPage>
      <h2
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />
      <Grid grid={grid} />
    </StyledPage>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

const StyledPage = styled.div`
  padding: ${largeGap};
  ${maxMedia.xSmall`
    padding: ${smallGap};
  `}
`

export default Page

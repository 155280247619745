import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/utils/Seo'
import Page from '../components/pages/Page'
import { AnimatedPage } from '../components/utils/AnimatedPage'

export const PAGE_QUERY = graphql`
  query PageQuery($slug: String!) {
    page: wpPage(slug: { eq: $slug }) {
      title
      id
      grid {
        colCount
        cont {
          col
          columnSpan
          cont
          align
          relId
          row
          type
          video {
            publicURL
            relativePath
          }
          localFile {
            publicURL
            relativePath
            id
            childImageSharp {
              gatsbyImageData(
                width: 1400
                layout: CONSTRAINED
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`

const PageTemplate = (props) => {
  return (
    <AnimatedPage>
      <Seo title={props.data.page.title} {...props.international} />
      <Page {...props} />
    </AnimatedPage>
  )
}

export default PageTemplate
